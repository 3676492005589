import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-107b0e6e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "user-module"
};
import PersonalAccount from "@/views/user-module/user-module-components/personal-account.vue";
export default {
  __name: 'index',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(PersonalAccount)]);
    };
  }
};